import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Typography } from '@mui/material'
import DomainIcon from '@mui/icons-material/Domain'
import AddIcon from '@mui/icons-material/Add'
import { getPermissions } from 'src/service/authService'
import { goToCreateRadiusGroup } from '../../routes/coordinator'
import useProtectedPage from 'src/hooks/useProtectedPage'
import RadiusGroupTable from './components/Table/RadiusGroupTable'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ModalCreatedRadiusGroup } from './components/Form/ModalCreatedRadiusGroup'

const RadiusGroupList = () => {
  useProtectedPage()
  const { t } = useTranslation()
  const history = useNavigate()
  const permissions = JSON.parse(getPermissions())

  return (
    <Container
      sx={{
        mt: '96px',
      }}
    >
      <AlertComponent />
      <ModalCreatedRadiusGroup />

      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <DomainIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-1px',
            }}
          >
            {t('radiusGroup.title')}
          </Typography>
        </Box>
      </Box>

      <Button
        onClick={() => goToCreateRadiusGroup(history)}
        disabled={permissions?.radiusGroups?.includes("radiusGroup:create")}
        variant="outlined"
        sx={{
          gap: '6px',
          width: '50%',
          height: '36px',
          mb: '40px',
          ':hover': {
            backgroundColor: '#FF5E1E',
            color: '#ffff',
          },
        }}
      >
        <AddIcon sx={{ width: '24px', height: '24px' }} />
        {t('radiusGroup.button.create')}
      </Button>

      <RadiusGroupTable />
    </Container>
  )
}
export default RadiusGroupList
