import { CompanyConfigModel } from 'src/models/company-model';
import * as yup from 'yup';

export const companyConfigDefaultValues: CompanyConfigModel = {
  urlController: '',
  urlRadius: '',
  willUseSMSOnRegister: false,
  willUseSMSForLogin: false,
  defaultUserGroup: '',
  shouldValidateCpf: false,
  sessionTimeoutInSeconds: 0,
  maxDownloadBandwidthInBps: 0
}

export const companyConfigSchemaValidation = yup.object({
  urlController: yup.string().required(),
  urlRadius: yup.string().required(),
  defaultUserGroup: yup.string().required(),
  willUseSMSOnRegister: yup.boolean().required(),
  willUseSMSForLogin: yup.boolean().required(),
  shouldValidateCpf: yup.boolean().default(false).required(),
  sessionTimeoutInSeconds: yup.number().default(3600),
  maxDownloadBandwidthInBps: yup.object().nullable().required()
})
