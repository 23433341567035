import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import copy from 'copy-to-clipboard'
import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import BrandingWatermarkOutlinedIcon from '@mui/icons-material/BrandingWatermarkOutlined'
import { ContentCopy } from '@mui/icons-material'
import { getCompanyId, getPermissions, getRole } from 'src/service/authService'

import InputRHF from '../../../../components/RHF/InputRHF'
import {
  radiusGroupDefaultValues,
  radiusGroupSchemaValidation,
} from '../../validators/radiusGroupSchemaValidation'
import { RadiusGroup } from 'src/models/radiusgroup-model'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { goToRadiusGroups } from 'src/routes/coordinator'
import { CompanySelect } from 'src/components/CompanySelect/CompanySelect'
import { Company } from 'src/models/company-model'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

interface RadiusGroupFormProps {
  data: RadiusGroup
  onSubmit: (data) => void
  isViewMode?: boolean
  viewId?: any
}

export const RadiusGroupForm = ({
  data,
  onSubmit,
  isViewMode,
  viewId,
}: RadiusGroupFormProps) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { isGlobalAdminSelected, selectedCompanyData } = useGlobalSelectedCompany()

  const TECNOIT: string = 'TECNOIT'
  const isEdit = Boolean(id)

  const form = useForm<any>({
    mode: 'all',
    defaultValues: radiusGroupDefaultValues,
    resolver: yupResolver(radiusGroupSchemaValidation),
  })
  const { control, handleSubmit, reset, formState, setValue, trigger, watch } =
    form

  const onChangeCompany = (company: Company) => {
    setValue('companyId', company)
  }

  const resetForm = () => {
    reset({ groupName: '' })
  }

  useEffect(() => {
    if (data) {
      reset(data)
      if (getRole() !== TECNOIT) {
        setValue('companyId', {
          companyId: getCompanyId(),
          companyName: data?.company?.companyName,
        })
      } else {
        setValue('companyId', {
          companyId: data.companyId,
          companyName: data?.company?.companyName,
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (getRole() !== 'TECNOIT') {
      setValue('companyId', {
        companyId: getCompanyId(),
        companyName: data?.company?.companyName,
      })
    }
  }, [])

  useEffect(() => {
    if (!isGlobalAdminSelected()) {
      onChangeCompany(selectedCompanyData as Company)
    }
    if (!isGlobalAdminSelected && !selectedCompanyData) {
      setValue('companyId', undefined)
    }
  }, [selectedCompanyData])

  const disableInput = () => {
    if (isViewMode) {
      return true
    }
  }

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Box>
        {getRole() === TECNOIT && (
          <CompanySelect
            disabled={disableInput()}
            control={control}
            onChangeAction={onChangeCompany}
          />
        )}
        <Box
          sx={{
            display: 'grid',
            mt: isViewMode ? '10px' : '28.5px',
            mb: isViewMode ? '5px' : '28px',
            gridColumn: {
              xs: 'span 12',
              sm: 'span 12',
              md: 'span 12',
              lg: 'span 12',
            },
          }}
        >
          <InputRHF
            required
            disabled={disableInput()}
            control={control}
            label={t('groupName.form.input.label.name')}
            name="groupName"
            placeholder={t('groupName.form.input.placeholder.name')}
            sx={{
              gridColumn: 'span 11',
              mb: isViewMode ? '4px' : '11px',
            }}
          />
        </Box>
      </Box>
      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToRadiusGroups}
          reset={() => resetForm()}
          size="100%"
          buttonName="radiusGroup.button.create"
        />
      )}
    </Box>
  )
}
