import { Box } from "@mui/material"

import useUnprotectedPage from "src/hooks/useUnprotectedPage"

import approvalLgpd from '../../assets/approval-lgpd.png'
import itBackground from '../../assets/icons/img2.png'

export function PrivacyPolicy () {
  useUnprotectedPage()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        backgroundImage: `url(${itBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center ',
        backgroundSize: '1920px 1080px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '80vw',
          height: '80vh',
          backgroundColor: 'white',
          paddingX: '2rem',
          borderRadius: '8px',
          overflow: 'scroll',
        }}
      >
        <p  style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Política de Privacidade do serviço itBuzz</h1>
        </p>
        <h2>1. Objetivo</h2>
        <p>
          Esta política tem por objetivo traçar as normativas sobre o uso da tecnologia itBuzz.
          Ao se registrar e autenticar, os usuários permitem a este serviço (este Aplicativo) identificálos e dar-lhes o acesso a serviços dedicados.
        </p>
        <h2>2. Definições</h2>
        <p><strong>Aplicativo: </strong> meio pelo qual os dados pessoais do usuário são coletados e processados.</p>
        <p><strong>Controlador de Dados ou Proprietário: </strong> A pessoa física ou jurídica, administração pública, agência ou outro órgão que, isoladamente ou em conjunto com outros determinar as finalidades e os meios de processamento dos Dados Pessoais, incluindo medidas de segurança relativas ao funcionamento e ao uso deste Serviço/Aplicativo.</p>
        <p><strong>Cookies: </strong> são rastreadores compostos por pequenos conjuntos de dados armazenados no navegador do Usuário.</p>
        <p><strong>Dados pessoais ou dados: </strong> quaisquer informações que diretamente, indiretamente ou em relação com outras informações - incluindo um número de identificação pessoal - permitam a identificação ou identificabilidade de uma pessoa física.</p>
        <p><strong>Processador de dados ou Contratante: </strong> A pessoa física ou jurídica, administração pública, agência ou outro órgão que processe os Dados Pessoais em nome do Controlador conforme descrito nesta política de privacidade.</p>
        <p><strong>Rastreador: </strong> qualquer tecnologia - como Cookies, identificadores únicos, web beacons, scripts embutidos, e-tags e fingerprinting - que permita o rastreamento de Usuários, por exemplo, acessando ou a armazenando informações no dispositivo do Usuário.</p>
        <p><strong>Serviço: </strong> serviço fornecido por este Aplicativo.</p>
        <p><strong>Titular dos dados: </strong>  a pessoa física a quem os dados pessoais se referem.</p>
        <h2>3. Diretrizes Gerais</h2>
        <p>O aplicativo está direcionado a identificação de usuários para acessos a serviços. Ǫuando os usuários se autenticam no aplicativo, são redirecionados para a plataforma de acesso ao serviço de wi-fi utilizado pela organização/instituição que o usuário está visitando.</p>
        <p>Os dados pessoais poderão ser fornecidos livremente pelo usuário, ou, no caso dos dados de utilização, coletados automaticamente ao se utilizar este aplicativo.</p>
        <p>A menos que especificado diferentemente, todos os dados solicitados por este aplicativo são obrigatórios e a falta de fornecimento destes dados poderá impossibilitar este aplicativo de fornecer os seus serviços. Nos casos em que este aplicativo afirmar especificamente que alguns dados não forem obrigatórios, os usuários ficam livres para deixarem de comunicar estes dados sem nenhuma consequência para a disponibilidade ou o funcionamento do serviço</p>
        <p>O proprietário dos dados poderá solicitar acesso e informações acerca dos seus dados fornecidos à plataforma/aplicativo por meio do contato com o DPO do controlador, cujo contato segue ao final deste documento.</p>
        <h2>4. Diretrizes Específicas</h2>
        <h3>4.1.1. Registro e autenticação fornecidos diretamente por este Aplicativo</h3>
        <p>Ao se registrar ou autenticar, os usuários permitem a este serviço (este aplicativo) identificá-los e dar-lhes o acesso a serviços dedicados. Os dados pessoais são coletados e armazenados somente para fins de registro ou identificação. Os dados coletados são necessários apenas para a prestação do serviço solicitado pelos usuários.</p>
        <h3>4.1.2. Registro direto (este aplicativo)</h3>
        <p>O usuário se registra ao preencher o formulário de inscrição e fornecer os Dados Pessoais diretamente para este aplicativo.</p>
        <p>Dados Pessoais processados: Cadastro de Pessoas Físicas (CPF), e-mail, nome e número de telefone.</p>
        <h3>4.1.3. Base jurídica para o processamento</h3>
        <p>O proprietário do aplicativo poderá processar os dados pessoais relacionados ao usuário se uma das hipóteses a seguir se aplicar:</p>
        <ol type='a'>
          <li>Os usuários tenham dado a sua anuência para uma ou mais finalidades específicas;</li>
          <li>O fornecimento dos dados for necessário para o cumprimento de um contrato com o usuário e/ou quaisquer obrigações pré-contratuais do mesmo;</li>
          <li>O processamento for necessário para o cumprimento de uma obrigação jurídica à qual o proprietário estiver sujeito;</li>
          <li>O processamento estiver relacionado a uma tarefa que for executada no interesse público ou no exercício de uma autorização oficial na qual o proprietário estiver investido;</li>
          <li>O processamento for necessário para a finalidade de interesses legítimos perseguidos pelo Proprietário ou por um terceiro.</li>
        </ol>
        <p>Em qualquer caso, o proprietário colaborará de bom grado para esclarecer qual a base jurídica que se aplica ao processamento, e em especial se o fornecimento de dados for um requisito obrigatório por força de lei ou contratual, ou uma exigência necessária para celebrar um contrato</p>
        <h3>4.1.4. Tempo de Retenção</h3>
        <p>Salvo especificação em contrário neste documento, os dados pessoais serão tratados e armazenados pelo tempo necessário para as finalidades para as quais foram coletados, e poderão ser retidos por mais tempo em razão de qualquer obrigação legal aplicável ou com base no consentimento dos usuários. Portanto: </p>
        <ol type='a'>
          <li>Os dados pessoais coletados para as finalidades relacionadas com a execução de um contrato entre o proprietário e o usuário serão conservados até que tal contrato tenha sido completamente cumprido.</li>
          <li>Os dados pessoais coletados para as finalidades relacionadas com os legítimos interesses do proprietário serão conservados pelo tempo que for necessário para cumprir tais finalidades.</li>
        </ol>
        <p>O proprietário poderá ter a permissão de conservar os dados pessoais por um prazo maior sempre que o usuário tiver dado a sua autorização para tal processamento, enquanto tal autorização não tiver sido retirada. Além disso, o proprietário poderá ficar obrigado a conservar os dados pessoais por um prazo maior sempre que necessário para o cumprimento de uma obrigação jurídica ou de um mandado de uma autoridade.</p>
        <p>Assim que o prazo de conservação vencer os Dados Pessoais serão apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito à portabilidade dos dados não poderão ter o seu cumprimento exigido após o vencimento do prazo de conservação.</p>
        <p>Os direitos dos usuários estão previstos no artigo 18 da LGPD e poderão exercer esses direitos, dentro dos limites permitidos por lei, incluindo o seguinte:</p>
        <ol type='a'>
          <li>obter confirmação sobre a existência de atividades de tratamento de suas informações pessoais;</li>
          <li>acesso a suas informações pessoais;</li>
          <li>fazer com que suas informações pessoais incompletas, inexatas ou desatualizadas sejam corrigidas;</li>
          <li>obter o anonimato, bloqueio ou eliminação de suas informações pessoais desnecessárias ou em excesso, ou de informações que não estejam sendo processadas de acordo com a LGPD;</li>
          <li>obter informações sobre a possibilidade de fornecer ou recusar sua anuência e as respectivas consequências;</li>
          <li>obter informações sobre os terceiros com quem compartilhamos suas informações pessoais;</li>
          <li>obter, mediante sua solicitação expressa, a portabilidade de suas informações pessoais (exceto informações anônimas) para outro fornecedor de produtos ou serviços, desde que nossos segredos comerciais e industriais continuem protegidos;</li>
          <li>obter a exclusão de suas informações pessoais tratadas, se o teve base em sua anuência, a menos que se apliquem uma ou mais exceções daquelas dispostas no art. 16 da LGPD;</li>
          <li>retirar sua anuência a qualquer momento;</li>
          <li>registrar uma reclamação com relação a suas informações pessoais à ANPD (Autoridade Nacional de Proteção de Dados) ou aos órgãos de proteção ao consumidor;</li>
          <li>opor-se a uma atividade de tratamento nos casos em que o tratamento não for realizado em conformidade com as disposições da lei;</li>
          <li>solicitar informações claras e adequadas a respeito dos critérios e procedimentos usados para uma decisão automatizada; e</li>
          <li>solicitar a revisão de decisões tomadas exclusivamente com base no tratamento automatizado de suas informações pessoais, que afetem seus interesses.</li>
        </ol>
        <p>Os usuários também possuem o direito de serem informados sobre a base jurídica utilizada para o tratamento dos dados pessoais, bem como, obter informações acerca das transferências de dados para o exterior, incluindo quaisquer organizações internacionais e ainda obter informações sobre as medidas de segurança tomadas pelo proprietário para proteger seus dados.</p>
        <h3>4.1.5. Detalhes sobre o direito de objetar ao processamento</h3>
        <p>Nos casos em que os dados pessoais forem processados por um interesse público, no exercício de uma autorização judicial ou por meio de um órgão oficial para o qual o proprietário estiver investido ou para finalidades dos interesses legítimos perseguidos pelo proprietário, os usuários poderão objetar tal processamento através do fornecimento de um motivo especial para justificar a objeção.</p>
        <p>Caso os usuários exerçam seu direito de objeção a utilização dos seus dados pessoais, os mesmos poderão sofrer restrição no uso e acesso ao aplicativo.</p>
        <h3>4.1.6. Como exercer estes direitos</h3>
        <p>Ǫuaisquer solicitações para exercer os direitos do usuário podem ser encaminhadas ao proprietário por meio dos detalhes de contato fornecidos neste documento. </p>
        <p>Tais pedidos são gratuitos e serão atendidos pelo proprietário com a maior brevidade possível e sempre no prazo máximo de 15 dias.</p>
        <p>Ǫualquer retificação ou exclusão de dados pessoais ou restrição de processamento será comunicada pelo proprietário a cada destinatário, se houver, a quem os dados pessoais foram divulgados, a menos que isso seja impossível ou implique um trabalho desproporcional.</p>
        <h3>4.1.7. Em que nos embasamos para processar suas informações pessoais</h3>
        <p>Podemos processar suas informações pessoais somente se tivermos uma base legal para tal processamento. As bases legais são as seguintes:</p>
        <ol type='a'>
          <li>sua anuência com as respectivas atividades de processamento;</li>
          <li>conformidade com uma obrigação legal ou regulamentar imposta a nós;</li>
          <li>o cumprimento de políticas públicas dispostas em leis ou regulamentações ou com base em contratos, acordos e instrumentos legais semelhantes;</li>
          <li>estudos conduzidos por entidades de pesquisa, preferivelmente realizados sobre informações pessoais anônimas;</li>
          <li>a execução de um contrato e seus procedimentos preliminares, nos casos em que você for parte do contrato;</li>
          <li>o exercício de nossos direitos em processos judiciais, administrativos ou de arbitragem;</li>
          <li>a proteção da saúde - em procedimentos realizados por entidades ou profissionais da saúde e tutela da vida;</li>
          <li>nossos interesses legítimos, desde que seus direitos e liberdades fundamentais não prevaleçam sobre tais interesses; e</li>
          <li>proteção ao crédito.</li>
        </ol>
        <p>O itBuzz utiliza, como base legal o cumprimento de obrigações contratuais com o contratante dos serviços do aplicativo e o consentimento do titular</p>
        <h3>4.1.8. Categorias de informações pessoais processadas</h3>
        <p>Para saber que categorias de suas informações pessoais são processadas, você poderá ler a seção intitulada "Informações detalhadas sobre o processamento de dados pessoais” neste documento</p>
        <h3>4.1.9. Por que processamos suas informações pessoais</h3>
        <p>Para saber por que processamos suas informações pessoais, você poderá ler sobre as seções intituladas "informações detalhadas sobre o processamento de dados pessoais" e "As finalidades do processamento" neste documento.</p>
        <p>Você nunca será discriminado ou sofrerá qualquer tipo de prejuízo se exercer seus direitos.</p>
        <h3>4.1.10. Como registrar sua solicitação</h3>
        <p>Você poderá registrar sua solicitação expressa de exercer seus direitos gratuitamente, em qualquer momento, usando os dados de contato fornecidos neste documento ou através de seu representante legal.</p>
        <h3>4.1.11. Como e quando nós responderemos a sua solicitação</h3>
        <p>Faremos o possível para responder imediatamente a suas solicitações. Em qualquer caso, caso seja impossível, faremos questão de comunicar a você os motivos de fato ou de direito que nos impeçam de atender imediatamente, ou de qualquer outra forma, cumprir suas solicitações.</p>
        <p>Nos casos em que não estivermos processando suas informações pessoais, indicaremos a pessoa física ou jurídica a quem você deve encaminhar suas solicitações se estivermos na posição de fazê-lo.</p>
        <p>Caso você registre uma solicitação de acesso ou de confirmação de processamento de informações pessoais, certifique-se de especificar se deseja que suas informações pessoais sejam entregues em formato eletrônico ou impresso.</p>
        <p>Você também precisará nos informar se deseja que respondamos sua solicitação imediatamente, sendo que neste caso responderemos de maneira simplificada ou se você precisa de uma resposta completa.</p>
        <p>Neste último caso, responderemos no prazo de 15 dias a contar do momento de sua solicitação, fornecendo todas as informações sobre a origem de suas informações pessoais, confirmação da existência ou não de registros, quaisquer critérios utilizados para o processamento e as finalidades do processamento, mantendo protegidos nossos segredos comerciais e industriais.</p>
        <p>Caso você solicite uma retificação, exclusão, anonimato ou bloqueio de informações pessoais, comunicaremos imediatamente sua solicitação a outras partes com quem compartilhamos suas informações pessoais, a fim de permitir que estes terceiros também cumpram sua solicitação - exceto nos casos em que tal comunicação se provar impossível ou envolver um esforço desproporcional de nosso lado.</p>
        <h3>4.1.12. Transferência de informações pessoais para fora do Brasil permitida por lei</h3>
        <p>Temos permissão para transferir suas informações pessoais para fora do território brasileiro nos seguintes casos:</p>
        <ol type='a'>
          <li>quando a transferência for necessária para fins de cooperação jurídica internacional entre órgãos públicos de inteligência, investigação e Ministério Público, de acordo com os meios legais dispostos no direito internacional;</li>
          <li>quando a transferência for necessária para proteger sua vida ou segurança física ou de terceiros;</li>
          <li>quando a transferência for autorizada pela ANPD;</li>
          <li>quando a transferência decorrer de um compromisso assumido em um acordo de cooperação internacional;</li>
          <li>quando a transferência for necessária para a execução de uma política pública ou atribuição legal de serviço público;</li>
          <li>quando a transferência for necessária para o cumprimento de uma obrigação legal ou regulamentar, a execução de um contrato ou procedimentos preliminares relacionados a um contrato ou o exercício regular de direitos em processos judiciais, administrativos ou de arbitragem.</li>
        </ol>
        <h3>4.1.13. Ação jurídica</h3>
        <p>Os dados pessoais dos usuários podem ser utilizados para fins jurídicos pelo proprietário em juízo ou nas etapas conducentes à possível ação jurídica decorrente de uso indevido deste serviço (este aplicativo) ou dos serviços relacionados.</p>
        <p>O usuário declara estar ciente de que o proprietário poderá ser obrigado a revelar os dados pessoais mediante solicitação das autoridades governamentais.</p>
        <h3>4.1.14. Logs do sistema e manutenção</h3>
        <p>Para fins de operação e manutenção este aplicativo e quaisquer serviços de terceiros poderão coletar arquivos que gravam a interação com este aplicativo (logs do sistema) ou usar outros dados pessoais (tais como endereço IP) para esta finalidade.</p>
        <h3>4.1.15. Mudanças nesta política de privacidade</h3>
        <p>O proprietário se reserva o direito de fazer alterações nesta política de privacidade a qualquer momento, através de notificação a seus usuários nesta página e possivelmente dentro deste serviço (este aplicativo) e/ou - na medida em que for técnica e juridicamente viável - enviando um aviso para os usuários através de quaisquer informações de contato disponíveis para o Proprietário.</p>
        <p>Caso as mudanças afetem as atividades de processamento realizadas com base na anuência do usuário, o proprietário coletará nova anuência do usuário.</p>
        <p>Esta política de privacidade se refere somente a este aplicativo, se não afirmado diferentemente neste documento.</p>
        <h3>4.1.16 Proprietário e Controlador de Dados</h3>
        <p><strong>TECNO - IT, TECNOLOGIA, SERVICOS E COMUNICACAO SA.</strong></p>
        <p>Av. Olinda, Nº 960, Ed. Condomínio Park Lozandes - Trade Tower 2 - 25º Andar, Salas 2503 a 2509 - Park Lozandes, Goiânia - GO, 74884-120.</p>
        <p>
          Todos da empresa são responsáveis pelo Programa de Privacidade de Dados, contudo, o responsável pela gestão e aplicação do Programa de Privacidade é o Encarregado, também chamado DPO ou Data Protection Officer, cujo contato é:
          <br /><br />
          <span><strong>Nome: </strong>Mychelle Brito</span>
          <br />
          <span><strong>E-mail: </strong>dpo@tecnoit.com.br</span>
        </p>
        <h2>5. Abrangência</h2>
        <p>Esta política aplica-se a todos os colaboradores da Tecno It, assim como qualquer outra pessoa, física ou jurídica, que trabalhe para ou em nome da Tecno It, localizada no Brasil ou no exterior.</p>
        <h2>6. Referências</h2>
        <ol type='i'>
          <li>Código de Conduta da Tecno It;</li>
          <li>Política de segurança da informação da Tecno It;</li>
          <li>ISO 27001;</li>
          <li>ISO 27002.</li>
        </ol>
        <h2>7. Aprovações:</h2>
        <p style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
          <img src={approvalLgpd} alt="Lista de aprovações deste documento" style={{ height: '150px' }} />
        </p>
      </Box>
    </Box>
  )
}
