import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export type ADITIONAL_FLAGS = 'FORTINET_FEATURES_POC'

export const getSystemAditionalFlags = async (): Promise<any> => {
  try {
    const res = await client().get(`${BASE_URL}/flags/company`)
    return res.data
  } catch (error) {
    return false
  }
}
