import { Point } from 'src/models/point-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getAccessPoints = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/accesspoint`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getAccessPointById = async (
  id: string
): Promise<Point | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/accesspoint/${id}`)
    return res.data
  } catch (error) {}
}

export const createAccessPoint = async (
  point: Point | any
): Promise<Point | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/accesspoint`, point)
    return res.data
  } catch (error) {
    return false
  }
}

export const editAccessPoint = async (
  id: string,
  point: Point
): Promise<Point | boolean> => {
  try {
    console.log(point)
    const res = await client().patch(`${BASE_URL}/accesspoint/${id}`, point)
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteAccessPointById = async (
  id: string | number
): Promise<Point | boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/accesspoint/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const recycleAccessPointById = async (id: string): Promise<Point | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/accesspoint/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const unarchiveAccessPointById = async (id: string): Promise<Point | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/accesspoint/${id}/unarchive`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const importCsv = async (data: FormData): Promise<Point | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/batch/accesspoint/`, data)
    return true
  } catch (error) {
    return false
  }
}

export const getAccessPointsAnalysis = async (options?: any) => {
  try {
    const res = await client().get(`${BASE_URL}/reports/access-point-analysis/`, options)
    return res.data
  } catch (error) {
    return false
  }
}
