import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridColumns } from '@mui/x-data-grid'
import { Chip } from '@mui/material'

import { deleteRadiusGroupById, getRadiusGroups, recycleRadiusGroupById } from 'src/service/radiusGroupService'
import { getPermissions } from 'src/service/authService'
import { Table } from 'src/components/Table/Table'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { goToEditRadiusGroup } from 'src/routes/coordinator'
import { ArrToNumberSort, NumberSort } from 'src/components/Table/SortingFunctions'
import CreateRadiusGroup from '../../CreateRadiusGroup'

export default function RadiusGroupTable() {
  const { t } = useTranslation()
  const { createCRUDAlert } = useContext(AlertContext)
  const [tableKey, setTableKey] = useState(0)
  const permissions = JSON.parse(getPermissions())

  const getData = async (options: any) => {
    const data = await getRadiusGroups(options)
    return { data: data.listRadiusGroups, totalPages: data.page, size: data?.size }
  }
  const handleDelete = async (id, name) => {
    const res = await deleteRadiusGroupById(id)
    if (res) createCRUDAlert(ROUTES.RADIUSGROUP, name, 'deleted')
    return
  }

  const handleRecycle = async (id, name) => {
    const res = await recycleRadiusGroupById(id)
    if (res) {
      createCRUDAlert(ROUTES.RADIUSGROUP, name, 'edited')
    }
  }

  const columns: GridColumns = [
    {
      field: 'groupName',
      headerName: t('table.header.radiusGroup'),
      width: 222,
      editable: false,
    },
  ]
  return (
    <Table
      columns={columns}
      getData={getData}
      key={tableKey}
      handleDelete={handleDelete}
      titleKey="groupName"
      goTo={goToEditRadiusGroup}
      defaultPageSize={5}
      model={'radiusGroup'}
      Children={CreateRadiusGroup}
      size={'16px'}
      mr="25px"
      permissionUpdate={permissions?.radiusGroup?.includes("radiusGroup:update")}
      permissionDelete={permissions?.radiusGroup?.includes("radiusGroup:delete")}
      permissionView={permissions?.radiusGroup?.includes("radiusGroup:view")}
      canShowRecycleButtons={true}
      permissionViewRecycle={permissions?.radiusGroup?.includes("radiusGroup:recycle:view")}
      permissionUpdateRecycle={permissions?.radiusGroup?.includes("radiusGroup:recycle:update")}
      handleRecycle={handleRecycle}
      canShowUnarchiveButtons={false}
    />
  )
}
