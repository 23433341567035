import axios from 'axios'
import i18next from 'i18next'
import { BASE_URL } from '../constants/url'
import { getLogoCompany } from './companyService'
import { ADITIONAL_FLAGS } from './system-services'

export const login = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/auth`, {
      email: email,
      password: password,
    })

    const tokenData = parseJwt(res.data.accessToken)
    storeCompanyId(tokenData.companyId)
    storeToken(res.data.accessToken)
    storeRole(res.data.role)
    storeDefaultPassword(res.data.defaultPassword)
    storePermissions(res.data.permissions)
    storeProfileImage(res.data.profileImage)

    if (tokenData.companyId) {
      await getLogoCompany().then((company) => {
        storeCompanyLogo(company.companyLogo)
        storeCompanyLogoSmall(company.companyLogoSmall)
      })
    }
    if (res?.data?.defaultLanguage) setLanguage(res.data.defaultLanguage)
    return true
  } catch (error: any) {
    return false
  }
}

export const isLogged = (): boolean => {
  return localStorage.getItem('token') ? true : false
}

export const logout = () => {
  clearToken()
  clearRole()
  clearLogo()
  clearUserEmail()
  clearDefaultPassword()
  clearPermissions()
}

const langCodeMap = {
  'Inglês (EN)': 'en',
  'Português (pt-BR)': 'pt-BR',
  'Espanhol (ES)': 'es',
}

const langNameMap = {
  'Inglês (EN)': 'EN',
  'Português (pt-BR)': 'PT',
  'Espanhol (ES)': 'ES',
}

const setLanguage = (lang) => {
  const code = langCodeMap[lang]
  const name = langNameMap[lang]
  if (code && name) {
    localStorage.setItem('language', JSON.stringify({ code, name }))
    i18next.changeLanguage(code)
    return
  }
  localStorage.setItem(
    'language',
    JSON.stringify({ code: 'pt-BR', name: 'PT' })
  )
  i18next.changeLanguage('pt-BR')
}

export const getToken = (): string | null => {
  return localStorage.getItem('token')
}

const storeToken = (token: string) => {
  localStorage.setItem('token', token)
}

const storeCompanyId = (id: string) => {
  localStorage.setItem('companyId', id)
}

const storeCompanyLogo = (id: string) => {
  localStorage.setItem('companyLogo', id)
}

const storeCompanyLogoSmall = (id: string) => {
  localStorage.setItem('companyLogoSmall', id)
}

const storePermissions = (permissions) => {
  localStorage.setItem('permissions', JSON.stringify(permissions))
}

const storeProfileImage = (profileImage) => {
  localStorage.setItem('profileImage', profileImage)
}

const clearLogo = () => {
  localStorage.removeItem('companyLogo')
  localStorage.removeItem('companyLogoSmall')
}

const clearToken = () => {
  localStorage.removeItem('token')
}

const clearPermissions = () => {
  localStorage.removeItem('permissions')
}

export const storeAditionalFlags = (aditionalFlags: ADITIONAL_FLAGS[]) => {
  localStorage.setItem('aditionalFlags', JSON.stringify(aditionalFlags))
}

export const getAditionalFlags = () => {
  const flags = localStorage.getItem('aditionalFlags') || '[]'
  return JSON.parse(flags)
}

export const getRole = () => {
  return localStorage.getItem('role')
}

export const getCompanyId = () => {
  return localStorage.getItem('companyId') || null
}

export const getDefaultPassword = () => {
  return localStorage.getItem('defaultPassword')
}

export const getProfileImage = () => {
  return localStorage.getItem('profileImage')
}

export const getPermissions = () => {
  return localStorage.getItem('permissions')
}

const storeDefaultPassword = (defaultPassword: string) => {
  localStorage.setItem('defaultPassword', defaultPassword)
}

const storeRole = (role: string) => {
  localStorage.setItem('role', role)
}

const clearRole = () => {
  localStorage.removeItem('role')
}

const clearDefaultPassword = () => {
  localStorage.removeItem('defaultPassword')
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const getUserEmail = (): string | null => {
  return localStorage.getItem('user.email')
}

export const storeUserEmail = (email: string) => {
  localStorage.setItem('user.email', email)
}

export const clearUserEmail = () => {
  localStorage.removeItem('user.email')
}
