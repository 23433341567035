import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import DomainIcon from '@mui/icons-material/Domain'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { RadiusGroup } from 'src/models/radiusgroup-model'
import { RadiusGroupForm } from './components/Form/RadiusGroupForm'
import {
  createRadiusGroup,
  editRadiusGroup,
  getRadiusGroupById
} from 'src/service/radiusGroupService'
import { goToRadiusGroups } from 'src/routes/coordinator'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ModalCreatedRadiusGroupContext } from 'src/context/ModalCreatedRadiusGroupContext'

interface Props {
  isViewMode?: boolean
  viewId?: any
}
const CreateRadiusGroup = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [radiusGroup, setRadiusGroup] = useState<RadiusGroup>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const { openModalRadiusGroup } = useContext(ModalCreatedRadiusGroupContext)

  const getRadiusGroup = async () => {
    if (id || isViewMode) {
      const radiusGroup = await getRadiusGroupById(id || viewId)
      setRadiusGroup(radiusGroup)
    }
  }

  const onSubmit = async (data) => {
    const validData = {
      ...data,
      companyId: data.companyId.id
    }
    if (id) {
      const result = await editRadiusGroup(id, validData)
      if (result) {
        createCRUDAlert(ROUTES.RADIUSGROUP, validData.groupName, 'edited')
        goToRadiusGroups(history)
      }
      return
    }
    const result = await createRadiusGroup(validData)
    if (result) {
      createCRUDAlert(ROUTES.RADIUSGROUP, validData.groupName, 'created')
      goToRadiusGroups(history)
    }
  }

  useEffect(() => {
    getRadiusGroup()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: isViewMode ? '12px' : '24px',
        }}
      >
        <DomainIcon sx={{ width: '30.55px', height: '28px' }} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            marginTop: '-1px',
          }}
        >
          {t('radiusGroup.title')}
        </Typography>
      </Box>
      <Box>
        <RadiusGroupForm
          data={radiusGroup!}
          onSubmit={onSubmit}
          isViewMode={isViewMode}
          viewId={viewId}
        />
      </Box>
    </Container>
  )
}
export default CreateRadiusGroup
