import { Clear } from '@mui/icons-material'
import RestoreIcon from '@mui/icons-material/Restore'
import {
  Divider,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActivityContext } from 'src/context/ActivityContext'
import { Activity, ActivityCompanyProps } from 'src/models/sideMenuAudit-model'
import {
  getAuditoryById,
  getAuditoryByObjectType,
} from 'src/service/auditoryService'
import { formatDate, formatTime } from 'src/util/formatDataAndTime'
import { Search } from '../Search/Search'

const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))
const typeMap = {
  objectId: getAuditoryById,
  objectType: getAuditoryByObjectType,
}
const activityLocaleMap = {
  Grupo: 'activity.object.type.grup',
  Empresa: 'activity.object.type.company',
  Administrador: 'activity.object.type.adm',
  'Access Point': 'activity.object.type.point',
  'Campanha de Marketing': 'activity.object.type.campain.marketing',
  'Captive Portal': 'activity.object.type.portal',
  'Enriquecimento de Perfil': 'activity.object.type.enrichment',
  'Papel': 'activity.object.type.role',
}
export default function ActivityHistory({
  path,
  keySubtitle,
}: ActivityCompanyProps) {
  const { t } = useTranslation()
  const [auditory, setAuditory] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const {
    openDrawer,
    handleOpen,
    handleClose,
    objectId,
    handleSetObjectId,
    search,
  } = useContext(ActivityContext)

  const getActivities = async (options?: any) => {
    const type = objectId ? 'objectId' : 'objectType'
    const res = await typeMap[type](objectId ? objectId : path, {
      ...options,
      size: 5,
    })
    setAuditory(res.listAuditory)
    setTotalPages(res.page)
  }

  useEffect(() => {
    getActivities()
  }, [path, objectId])

  const getLocaleObjectTypeKey = (objectType) => activityLocaleMap[objectType]
  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      handleClose()
    }

  const getTitleHistoryActivity = (activity: Activity): string => {
    if (activity.apiAction === 'CREATE')
      return `${t('activity.created')} ${t(
        `${getLocaleObjectTypeKey(activity.objectType)}`
      )}`
    if (activity.apiAction === 'UPDATE')
      return `${t('activity.edited')} ${t(
        `${getLocaleObjectTypeKey(activity.objectType)}`
      )}`
    if (activity.apiAction === 'DELETE')
      return `${t('activity.deleted')} ${t(
        `${getLocaleObjectTypeKey(activity.objectType)}`
      )}`
    if (activity.apiAction === 'RECOVER')
      return `${t('activity.recover')} ${t(
        `${getLocaleObjectTypeKey(activity.objectType)}`
      )}`
    if (activity.apiAction === 'UNARCHIVE')
      return `${t('activity.unarchive')} ${t(
        `${getLocaleObjectTypeKey(activity.objectType)}`
      )}`
    return ''
  }

  const getSubtitleHistoryActivity = (
    key: string,
    activity: Activity
  ): string => {
    if (activity.apiAction === 'CREATE' || activity.apiAction === 'UPDATE') {
      return `"${stringToObject(activity.newValue)[key] || '???'}"`
    }
    if (activity.apiAction === 'DELETE') {
      return `"${stringToObject(activity.oldValue)[key] || '???'}" `
    }
    return ''
  }

  const stringToObject = (json: string) => {
    return JSON.parse(json)
  }

  const list = () => (
    <Box
      sx={{
        mt: '60px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        pb: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '50px',
            m: 2,
          }}
        >
          <IconButton
            onClick={toggleDrawer()}
            sx={{
              height: '16px',
              width: '16px',
              ':hover': {
                color: '#FF5E1E',
              },
            }}
          >
            <Clear sx={{ height: '16px', width: '16px' }} />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.15px',
              color: '#FF5E1E',
              display: ' flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <RestoreIcon
              sx={{ width: '20px', height: '18px', color: '#FF5E1E' }}
            />
            {t('activity.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 2,
            background: '#F9F9F9',
            p: 2,
            boxShadow: ' rgba(0, 0, 0, 0.12) 0px 1px 0px 0px inset',
          }}
        >
          <Search onSearch={(search) => getActivities({ search })} />
        </Box>
        {auditory?.map((item: Activity, index) => {
          return (
            <div key={`${item.objectId}-${index}`}>
              <Tooltip title={t('activity.tip.copy')}>
                <Box
                  onClick={() => {
                    const copyText = `${getSubtitleHistoryActivity(
                      keySubtitle,
                      item
                    )} ${getTitleHistoryActivity(item)}`
                    navigator.clipboard.writeText(copyText)
                  }}
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                    ':hover': {
                      '#title': {
                        color: '#FF5E1E',
                      },
                      '#subtitle': {
                        color: '#FF5E1E',
                      },
                      backgroundColor: 'rgba(255, 94, 30, 0.04)',
                    },
                  }}
                >
                  <Typography
                    id="title"
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    {getTitleHistoryActivity(item)}
                  </Typography>
                  <Typography
                    id="subtitle"
                    sx={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.1px',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                  >
                    {getSubtitleHistoryActivity(keySubtitle, item)}
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    {item.adminUser.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.6)',
                    }}
                  >
                    {`${formatDate(item.modifiedOn)} ${t(
                      'activity.object.time.at'
                    )} ${formatTime(item.modifiedOn)}`}
                  </Typography>
                </Box>
              </Tooltip>
              <Divider sx={{ width: '100%' }} />
            </div>
          )
        })}
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Pagination
          count={totalPages - 1}
          page={page}
          siblingCount={0}
          boundaryCount={1}
          shape="rounded"
          onChange={(_, page) => {
            getActivities({ page })
            setPage(page)
          }}
        />
      </Box>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        sx={{
          mr: openDrawer ? '-62px' : '0px',
          p: 0,
          minWidth: '32px',
          height: '32px',
          color: 'rgba(0, 0, 0, 0.6)',
          borderColor: 'rgba(0, 0, 0, 0.6)',
          ':hover': {
            color: ' #FF5E1E',
            borderColor: ' #FF5E1E',
          },
        }}
        variant="outlined"
        onClick={() => {
          handleSetObjectId(undefined)
          if (openDrawer) {
            handleClose()
            return
          }
          handleOpen()
        }}
      >
        <RestoreIcon sx={{ width: '25px', height: '20px' }} />
      </Button>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="right"
          open={openDrawer}
        >
          {list()}
        </Drawer>
      </Box>
    </Box>
  )
}
