import { Box } from "@mui/material"

import useUnprotectedPage from "src/hooks/useUnprotectedPage"

import itBackground from '../../assets/icons/img2.png'

export function DataExclusion () {
  useUnprotectedPage()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        backgroundImage: `url(${itBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center ',
        backgroundSize: '1920px 1080px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '80vw',
          height: '80vh',
          backgroundColor: 'white',
          paddingX: '2rem',
          borderRadius: '8px',
          overflow: 'scroll',
        }}
      >
        <p  style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Procedimento para Exclusão de Dados</h1>
        </p>
        <h2>1. Qual o procedimento para solitar a exclusão de dados</h2>
        <p>Ǫuaisquer solicitações para exercer os direitos do usuário podem ser encaminhadas ao proprietário por meio dos detalhes de contato fornecidos neste documento. </p>
        <p>Tais pedidos são gratuitos e serão atendidos pelo proprietário com a maior brevidade possível e sempre no prazo máximo de 15 dias.</p>
        <p>Ǫualquer retificação ou exclusão de dados pessoais ou restrição de processamento será comunicada pelo proprietário a cada destinatário, se houver, a quem os dados pessoais foram divulgados, a menos que isso seja impossível ou implique um trabalho desproporcional.</p>
        <h2>2. Como registrar sua solicitação</h2>
        <p>Você poderá registrar sua solicitação expressa de exercer seus direitos gratuitamente, em qualquer momento, usando os dados de contato fornecidos neste documento ou através de seu representante legal.</p>
        <h2>3. Como e quando nós responderemos a sua solicitação</h2>
        <p>Faremos o possível para responder imediatamente a suas solicitações. Em qualquer caso, caso seja impossível, faremos questão de comunicar a você os motivos de fato ou de direito que nos impeçam de atender imediatamente, ou de qualquer outra forma, cumprir suas solicitações.</p>
        <p>Nos casos em que não estivermos processando suas informações pessoais, indicaremos a pessoa física ou jurídica a quem você deve encaminhar suas solicitações se estivermos na posição de fazê-lo.</p>
        <p>Caso você registre uma solicitação de acesso ou de confirmação de processamento de informações pessoais, certifique-se de especificar se deseja que suas informações pessoais sejam entregues em formato eletrônico ou impresso.</p>
        <p>Você também precisará nos informar se deseja que respondamos sua solicitação imediatamente, sendo que neste caso responderemos de maneira simplificada ou se você precisa de uma resposta completa.</p>
        <p>Neste último caso, responderemos no prazo de 15 dias a contar do momento de sua solicitação, fornecendo todas as informações sobre a origem de suas informações pessoais, confirmação da existência ou não de registros, quaisquer critérios utilizados para o processamento e as finalidades do processamento, mantendo protegidos nossos segredos comerciais e industriais.</p>
        <p>Caso você solicite uma retificação, exclusão, anonimato ou bloqueio de informações pessoais, comunicaremos imediatamente sua solicitação a outras partes com quem compartilhamos suas informações pessoais, a fim de permitir que estes terceiros também cumpram sua solicitação - exceto nos casos em que tal comunicação se provar impossível ou envolver um esforço desproporcional de nosso lado.</p>
        <h2>4. Proprietário e Controlador de Dados</h2>
        <p><strong>TECNO - IT, TECNOLOGIA, SERVICOS E COMUNICACAO SA.</strong></p>
        <p>Av. Olinda, Nº 960, Ed. Condomínio Park Lozandes - Trade Tower 2 - 25º Andar, Salas 2503 a 2509 - Park Lozandes, Goiânia - GO, 74884-120.</p>
        <p>
          Todos da empresa são responsáveis pelo Programa de Privacidade de Dados, contudo, o responsável pela gestão e aplicação do Programa de Privacidade é o Encarregado, também chamado DPO ou Data Protection Officer, cujo contato é:
          <br /><br />
          <span><strong>Nome: </strong>Mychelle Brito</span>
          <br />
          <span><strong>E-mail: </strong>dpo@tecnoit.com.br</span>
        </p>
      </Box>
    </Box>
  )
}
