import axios from 'axios'
import { RadiusGroup } from 'src/models/radiusgroup-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const instance = axios.create()

export const getRadiusGroups = async (options?: any) => {
  try {
    const res = await client().get(`${BASE_URL}/radius-group`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getRadiusGroupById = async (
  id: string
): Promise<RadiusGroup | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/radius-group/${id}`)
    return res.data
  } catch (error) { }
}

export const getLogoRadiusGroup = async (): Promise<RadiusGroup | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/assets/radius-group/`)
    return res.data
  } catch (error) { }
}

export const createRadiusGroup = async (
  company: RadiusGroup
): Promise<RadiusGroup | boolean> => {
  try {
    const res = await client().post(`${BASE_URL}/radius-group`, company)
    return res.data
  } catch (error) {
    return false
  }
}

export const editRadiusGroup = async (
  id: string,
  company: RadiusGroup
): Promise<boolean> => {
  try {
    const result = await client().patch(`${BASE_URL}/radius-group/${id}`, company)
    return result.status === 200
  } catch (error) {
    return false
  }
}

export const recycleRadiusGroupById = async (id: string): Promise<RadiusGroup | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/radius-group/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteRadiusGroupById = async (
  id: string | number
): Promise<boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/radius-group/${id}`)
    return true
  } catch (error) {
    return false
  }
}