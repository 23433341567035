/*
 *  HELPERS
 */
export function goToLogin(history: any) {
  history('/login')
}

export function goToLoginNoAutorized(history: any) {
  history('/no-authorized')
}

export function goToDashboard(history: any) {
  history('/dashboard')
}

export function goToSupport(history: any) {
  history('/sistema/suporte')
}

/*
 *  PORTAIS
 */
export function goToPortal(history: any) {
  history('/gerenciamento/portais')
}

export function goToCreatePortal(history: any) {
  history('/gerenciamento/portal')
}

export function goToEditPortal(history: any, id: string) {
  history(`/gerenciamento/portal/${id}`)
}

/*
 *  GRUPOS
 */
export function goToPointGroups(history: any) {
  history('/gerenciamento/grupo-de-pontos')
}

export function goToCreatePointGroups(history: any) {
  history('/gerenciamento/grupo-de-ponto')
}

export function goToEditPointGroups(history: any, id: string) {
  history(`/gerenciamento/grupo-de-ponto/${id}`)
}

/*
 *  CAMPANHAS
 */
export function goToCampaigns(history: any) {
  history('/gerenciamento/campanhas')
}

export function goToCreateCampaign(history: any) {
  history('/gerenciamento/campanha')
}

export function goToCampaignResult(history: any, id?: string) {
  if(id !== undefined) {
    history(`/gerenciamento/resultado/campanha/${id}`)
  } else {
    history(`/gerenciamento/resultado/campanha/todas`)
  }
}
export function goToEditCampaign(history: any, id: string) {
  history(`/gerenciamento/campanha/${id}`)
}

/*
 *  PONTOS
 */
export function goToPoints(history: any) {
  history('/gerenciamento/pontos')
}

export function goToCreatePoint(history: any) {
  history('/gerenciamento/ponto')
}

export function goToEditPoint(history: any, id: string) {
  history(`/gerenciamento/ponto/${id}`)
}

/*
 *  EMPRESAS
 */
export function goToCompany(history: any) {
  history('/empresas')
}

export function goToCreateCompany(history: any) {
  history('/empresa')
}

export function goToEditCompany(history: any, id: string) {
  history(`/empresa/${id}`)
}

export function goToCompanyConfiguration (history: any) {
  history('/configuracoes')
}

/*
 *  ENRIQUECIMENTO
 */
export function goToEnrichment(history: any) {
  history('/enriquecimentos')
}

export function goToCreateEnrichment(history: any) {
  history('/enriquecimento')
}

export function goToEditEnrichment(history: any, id: string) {
  history(`/enriquecimento/${id}`)
}

/*
 *  USUÁRIOS
 */
export function goToUsers(history: any) {
  history('/usuarios')
}

export function goToCreateUser(history: any) {
  history('/usuario')
}

export function goToEditUser(history: any, id: string) {
  history(`/usuario/${id}`)
}

/*
 *  VISITANTES
 */
export function goToVisitors(history: any) {
  history(`/visitantes`)
}
export function goToEditVisitor(history: any, id: string) {
  history(`/visitantes/${id}`)
}
/*
 *  ROLES
 */
export function goToRoles(history: any) {
  history('/papeis')
}

export function goToCreateRole(history: any) {
  history('/papel')
}

export function goToEditRole(history: any, id: string) {
  history(`/papel/${id}`)
}

/**
 * NOTIFICATIONS
 */

export function goToCreateNotification(history: any) {
  history('/notificacao')
}

/*
 *  FREQUENTLY ASKED QUESTIONS
 */
export function goToFaqs(history: any) {
  history('/gerenciamento/perguntas-frequentes')
}

export function goToCreateFaq(history: any) {
  history('/gerenciamento/pergunta-frequente')
}

export function goToEditFaq(history: any, id: string) {
  history(`/gerenciamento/pergunta-frequente/${id}`)
}

/*
 *  TRAINING VIDEOS
 */
export function goToVideos(history: any) {
  history('/gerenciamento/video-aulas')
}

export function goToCreateVideo(history: any) {
  history('/gerenciamento/video-aula')
}

export function goToEditVideo(history: any, id: string) {
  history(`/gerenciamento/video-aula/${id}`)
}

/*
* RADIUS GROUPS
 */
export function goToRadiusGroups(history: any) {
  history('/gerenciamento/grupos-radius')
}

export function goToCreateRadiusGroup(history: any) {
  history('/gerenciamento/grupo-radius')
}

export function goToEditRadiusGroup(history: any, id: string) {
  history(`/gerenciamento/grupo-radius/${id}`)
}

/*
 *  ERRORS
 */
export function goToError401(history: any) {
  history('/error401')
}

export function goToError403(history: any) {
  history('/error403')
}

export function goToError404(history: any) {
  history('/error404')
}
