import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'
import { ContentCopy } from '@mui/icons-material'
import { IconButton, TextField, Tooltip } from '@mui/material'

import { NoticeModal } from 'src/components/Modal/NoticeModal'
import { ModalCreatedRadiusGroupContext } from 'src/context/ModalCreatedRadiusGroupContext'

export const ModalCreatedRadiusGroup = () => {
  const { t } = useTranslation()
  const { open, id, handleCloseModal } = useContext(ModalCreatedRadiusGroupContext)

  return (
    <NoticeModal
      type="success"
      children={
        <TextField
          value={`http://portal.itbuzz.com.br/${'<controladora>'}/${id}`}
          disabled
          sx={{
            fontSize: '14px',
            width: '100%',
          }}
          InputProps={{
            endAdornment: (
              <Tooltip title={t('copy.id')} placement="left">
                <IconButton
                  onClick={() => copy('http://portal.itbuzz.com.br/<controladora>/' + id)}
                  sx={{
                    borderRadius: '4px',
                    minWidth: '32px',
                    color: '#00000099',
                    ':hover': {
                      color: 'white',
                      backgroundColor: '#00000099',
                    },
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      }
      title={t('modal.groupName.copy.id.title')}
      subtitle={t('modal.groupName.copy.id.subtitle')}
      open={open}
      handleClose={() => handleCloseModal(!open)}
    />
  )
}
