import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'

import { PointForm } from './components/Form/PointForm'
import useProtectedPage from 'src/hooks/useProtectedPage'
import { Point } from 'src/models/point-model'
import {
  createAccessPoint,
  editAccessPoint,
  getAccessPointById,
} from 'src/service/accesspointService'
import { useTranslation } from 'react-i18next'
import MapProvider from 'src/context/MapContext'
import { goToPoints } from 'src/routes/coordinator'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
interface Props {
  isViewMode?: boolean
  viewId?: any
}

const CreatePoint = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [point, setPoint] = useState<Point>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)

  const getPoint = async () => {
    if (id || isViewMode) {
      const point = await getAccessPointById(id || viewId)
      setPoint(point)
    }
  }

  const onSubmit = async (data) => {
    if (data?.id) {
      const {
        apName,
        apDefaultGroup,
        geoLatitude,
        geoLongitude,
        geoAddress,
        ipAddress,
        macAddress,
        companyId,
        isActive,
        isArchived,
        id,
      } = data
      const res = await editAccessPoint(id, {
        apName,
        apDefaultGroup,
        geoLatitude,
        geoLongitude,
        geoAddress,
        ipAddress,
        macAddress: macAddress.replace(/[^0-9a-fA-F]/g, '').slice(0, 12),
        newCompanyId: companyId.id,
        isActive,
        isArchived
      })
      if (res) {
        createCRUDAlert(ROUTES.POINT, data.apName, 'edited')
        goToPoints(history)
      }
      return
    }
    const res = await createAccessPoint({
      ...data,
      companyId: data.companyId.id,
    })
    if (res) {
      createCRUDAlert(ROUTES.POINT, data.apName, 'created')
      goToPoints(history)
    }
  }

  useEffect(() => {
    getPoint()
  }, [])

  return (
    <MapProvider>
      <Container
        sx={{
          mt: isViewMode ? '' : '96px',
          '.gm-style': {
            div: {
              cursor: 'default',
              ':active': {
                cursor: 'grabbing',
              },
            },
          },
        }}
      >
        <AlertComponent isView={isViewMode}/>

        <Box
          component="div"
          sx={{
            color: '#FF5E1E',
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: isViewMode ? '10px' : '24px',
          }}
        >
          <SettingsInputAntennaIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
            }}
          >
            {t('point.title')}
          </Typography>
        </Box>

        <PointForm data={point!} onSubmit={onSubmit} isViewMode={isViewMode} />
      </Container>
    </MapProvider>
  )
}
export default CreatePoint
