import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'

import { AlertContext } from 'src/context/AlertContext'
import { getSystemGroups } from 'src/service/marketingCampaignService'
import InputSelectRHF from '../RHF/InputSelectRHF'

export const GroupSelect = (props: any) => {
  const [groups, setGroups] = useState([])
  const { createAlert } = useContext(AlertContext)

  const getData = async () => {
    if (!props?.companyId) {
      setGroups([])
      return
    }
    const data = await getSystemGroups({
      params: {
        size: 100000000,
        companyId: props?.companyId,
      },
    })
    setGroups(data?.listGroups || [])
    if (!data?.listGroups) {
      createAlert('/', t('form.pointgroup.notify.no.groups'))
    }
  }

  useEffect(() => {
    getData()
  }, [props?.companyId])

  return (
    <InputSelectRHF
      name="groups"
      options={groups}
      label={t('campaign.select.groups')}
      placeholder={t('campaign.select.groups')}
      noOptionsText={t('noRowsLabel.company.has.no.points')}
      multiple
      isOptionEqualToValueFn={(option: any, value: any) =>
        option.id === value.id
      }
      getOptionLabel={(value: any) => (value.groupName ? value.groupName : '')}
      sx={{
        width: '100%',
        height: '48px',
      }}
      {...props}
      renderOption={(props, option: any, { inputValue }) => {
        const matches = match(option.groupName, inputValue, {
          insideWords: true,
        })
        const parts = parse(option.groupName, matches)

        return (
          <li {...props}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? '#FF5E1E' : '#000000DE',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}
