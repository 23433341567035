import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import { Box, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlertComponent } from 'src/components/Alert/AlertComponent';
import useProtectedPage from 'src/hooks/useProtectedPage';
import VisitorTable from './components/Table/VisitorTable';

const VisitorsList = () => {
  useProtectedPage()
  const { t } = useTranslation()

  return (
    <Container sx={{ mt: '96px' }}>
      <AlertComponent />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Groups3OutlinedIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: '#FF5E1E',
              marginBottom: '-4px',
            }}
          >
            {t('visitors.title')}
          </Typography>
        </Box>
      </Box>
      <VisitorTable />
    </Container>
  )
}
export default VisitorsList
