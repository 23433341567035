import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { Point } from 'src/models/point-model'

export const pointDefaultValues: Point = {
  companyId: null,
  apName: '',
  isActive: true,
  isArchived: false,
  geoLatitude: null,
  geoLongitude: null,
  geoAddress: '',
  ipAddress: null,
  macAddress: null,
  apDefaultGroup: null,
}

const mode: keyof ValidationMode = 'all'

export const pointSchemaValidation = yup.object({
  apName: yup.string().required('required'),
  isActive: yup.boolean(),
  isArchived: yup.boolean().default(false),
  geoLatitude: yup.string().required('required'),
  geoLongitude: yup.string().required('required'),
  geoAddress: yup.string().min(1).required('required'),
  ipAddress: yup.string().typeError('invalid').required('required'),
  macAddress: yup.string().typeError('invalid').required('required'),
  companyId: yup
    .object()
    .typeError('invalid')
    .test((value, context) => {
      if (value.id || value.companyId) {
        return true
      }
      return context.createError({
        message: `required`,
        path: 'companyId',
      })
    }),
})

export const pointUseFormArgs = {
  mode,
  defaultValues: pointDefaultValues,
  resolver: yupResolver(pointSchemaValidation),
}

export interface PointTypes
  extends yup.InferType<typeof pointSchemaValidation> {}
