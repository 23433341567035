import AddIcon from '@mui/icons-material/Add'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import { Box, Button, Container, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { PreviewModal, PreviewMode } from 'src/components/Modal/PreviewModal'
import { ActivityContext } from 'src/context/ActivityContext'
import { getPermissions } from 'src/service/authService'
import ActivityHistory from '../../components/SideMenuAudit/ActivityHistory'
import { goToCreateUser, goToRoles } from '../../routes/coordinator'
import { ModalSecurityAlerts } from './components/Modal/ModalSecurityAlerts'
import UserTable from './components/Table/UserTable'

const UserManager = () => {
  const { t } = useTranslation()
  const history = useNavigate()
  const { openDrawer } = useContext(ActivityContext)
  const permissions = JSON.parse(getPermissions())
  const [showPreview, setShowPreview] = useState(false)

  return (
    <Container
      sx={{
        mt: '96px',
        '.MuiDataGrid-row': {
          minHeight: '62px!important',
          '.MuiDataGrid-cell': {
            paddingTop: '8px',
            paddingBottom: '8px',
            minHeight: '62px!important',
          },
        },
      }}
    >
      <AlertComponent />
      <PreviewModal
        handleClose={() => setShowPreview(false)}
        open={showPreview}
        title={`${t('securityAlert.title')}`}
        mode={PreviewMode.view}
        onConfirm={() => {
          setShowPreview(false)
        }}
      >
        <ModalSecurityAlerts />
      </PreviewModal>
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '24px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <PersonOutlineOutlinedIcon />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '160%',
              letterSpacing: ' 0.15px',
              color: ' #FF5E1E',
              marginTop: '-3px',
            }}
          >
            {t('user.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >{
          permissions['security-alerts'] && permissions['security-alerts']?.includes('security-alerts:list') &&
            <Button
              sx={{
                mr: '5px',
                minWidth: '25px',
                width: '32px',
                height: '32px',
                color: 'rgba(0, 0, 0, 0.6)',
                borderColor: 'rgba(0, 0, 0, 0.6)',
                ':hover': {
                  color: ' #FF5E1E',
                  borderColor: ' #FF5E1E',
                },
              }}
              variant="outlined"
              onClick={() => {
                setShowPreview(true)
              }}
            >
              <PrivacyTipIcon sx={{ width: '25px', height: '20px' }} />
            </Button>
          }
          {
            permissions?.auditory?.includes('auditory:list') && <ActivityHistory path={'usuarios'} keySubtitle={'name'} />
          }
        </Box>
      </Box>
      <Box
        sx={{
          mb: '40px',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
            lg: 'row',
            xl: 'row',
          },
          gap: '16px',
          width: openDrawer ? 'calc(100% - 280px)' : '100%',
        }}
      >
        <Button
          onClick={() => goToCreateUser(history)}
          disabled={!permissions.admin?.includes("admin:create")}
          variant="outlined"
          sx={{
            gap: '6px',
            width: openDrawer ? '100%' : '49%',
            height: '36px',
            ':hover': {
              backgroundColor: '#FF5E1E',
              color: '#ffff',
            },
          }}
        >
          <AddIcon sx={{ width: '24px', height: '24px' }} />
          {t('form.button.user')}
        </Button>
        <Button
          onClick={() => goToRoles(history)}
          disabled={!permissions.role?.includes("role:create")}
          variant="outlined"
          sx={{
            gap: '8px',
            width: openDrawer ? '100%' : '49%',
            height: '36px',
            color: 'rgba(0, 0, 0, 0.6)',
            borderColor: 'rgba(0, 0, 0, 0.6)',
            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: '#ffff',
              borderColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          <PersonOutlineOutlinedIcon sx={{ width: '24px', height: '24px' }} />
          {t('form.button.role')}
        </Button>
      </Box>

      <UserTable />
    </Container>
  )
}
export default UserManager
