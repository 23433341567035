import { CompanyConfigModel } from 'src/models/company-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getCompanyConfig = async () => {
  try {
    const res = await client().get(`${BASE_URL}/config`)
    return res.data as CompanyConfigModel
  } catch (error) {
    return false
  }
}

export const editCompanyConfig = async (
  point: CompanyConfigModel
): Promise<CompanyConfigModel | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/config`, point)
    return res.data
  } catch (error) {
    return false
  }
}
