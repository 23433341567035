import { createContext, ReactNode, useState } from 'react'

const defaultState = {
  open: false,
  handleOpen: () => { },
  handleClose: () => { },
}

interface ContextProviderProps {
  children: ReactNode
}

export const ModalCreatedRadiusGroupContext = createContext<any>(defaultState)

function ModalIdRadiusGroupProvider({ children }: ContextProviderProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [page, setPage] = useState<string>()
  const [id, setId] = useState<string>()

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const openModalRadiusGroup = (page: string, id: string) => {
    setPage(page)
    setId(id)
    handleOpenModal()
  }

  const openModal = (route, id) => {
    openModalRadiusGroup(route, id)
  }

  return (
    <ModalCreatedRadiusGroupContext.Provider
      value={{
        id,
        open,
        handleCloseModal,
        handleOpenModal,
        openModalRadiusGroup,
        openModal,
      }}
    >
      {children}
    </ModalCreatedRadiusGroupContext.Provider>
  )
}

export default ModalIdRadiusGroupProvider
