import { Divider, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SecurityAlert } from 'src/models/securityAlert-model'
import { getSecurityAlerts } from 'src/service/userAdminsService'

export const ModalSecurityAlerts = () => {
  const { t } = useTranslation()
  const [auditory, setAuditory] = useState<any[]>([])

  const getData = async () => {
    const data = await getSecurityAlerts()
    setAuditory(data)
  }

  useEffect(() => {
    getData()
  }, [])

  const getTitleHistoryActivity = (activity: SecurityAlert): string => {
    if (activity.motive === 'TOO_MANY_ATTEMPTS')
      return `O Usuário ` + activity.user.name + ` da empresa ` + activity.company.companyName + ` teve seu login bloqueado por excesso tentativas com o IP ` + activity.userIp
    if (activity.motive === 'USER_INACTIVE_ATTEMPT')
      return `O Usuário ` + activity.user.name + ` da empresa ` + activity.company.companyName + ` tentou realizar login com o IP ` + activity.userIp + ` mas o usuário está desativado.`
  }

  return (
    <Box
      sx={{
        display: 'row',
        mt: 2,
        background: '#F9F9F9',
        p: 2,
        // boxShadow: ' rgba(0, 0, 0, 0.12) 0px 1px 0px 0px inset',
      }}
    >
      {auditory.length > 0 && auditory?.map((item: SecurityAlert, index) => {
        return (
          <div key={`${item.id}-${index}`}>
            <Tooltip title={t('activity.tip.copy')}>
              <Box
                sx={{
                  p: 2,
                  cursor: 'pointer',
                  ':hover': {
                    '#title': {
                      color: '#FF5E1E',
                    },
                    '#subtitle': {
                      color: '#FF5E1E',
                    },
                    backgroundColor: 'rgba(255, 94, 30, 0.04)',
                  },
                }}
              >
                <Typography
                  id="title"
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}
                >
                </Typography>
                <Typography
                  id="subtitle"
                  sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.1px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  {getTitleHistoryActivity(item)}
                </Typography>
                <br />
              </Box>
            </Tooltip>
            <Divider sx={{ width: '100%' }} />
          </div>
        )
      })}
      {auditory.length === 0 && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          Ainda não foram gerados alertas de segurança.
        </Typography>
      )}
    </Box>
  )
}
