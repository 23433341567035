import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'
import { RadiusGroup } from '../../../models/radiusgroup-model'

export const MSG_REQUIRED_FIELD = 'O campo é obrigatório.'

export const radiusGroupDefaultValues: RadiusGroup = {
  id: '',
  companyId: '',
  groupName: '',
}

const mode: keyof ValidationMode = 'all'
export const radiusGroupSchemaValidation = yup.object({
  id: yup.string(),
  groupName: yup.string().required('required')
})

export const radiusGroupUseFormArgs = {
  mode,
  defaultValues: radiusGroupDefaultValues,
  resolver: yupResolver(radiusGroupSchemaValidation),
}

export interface RadiusGroupTypes
  extends yup.InferType<typeof radiusGroupSchemaValidation> { }
